import React, { useState, useRef } from "react"

// Components
import SidebarLayout from "../../../layouts/SidebarLayout"
import Section from "../../../components/section/Section"
import Seo from "../../../components/seo"
import ogImage from "../../../images/seo/testimonials/testimonials.png"
import ContentNavigation from "../../../components/scrollspy"

// Libraries / Components for Preview
import PreviewBlock from "../../../components/preview"

// Hero
import TestimonialHeroComponent from "../../../library/components/testimonial/react/_preview/TestimonialHeroComponent"

// Preview
import TestimonialAllSimpleBasicPreview from "../../../library/components/testimonial/react/_preview/simple-basic"
import TestimonialAllSimpleCardPreview from "../../../library/components/testimonial/react/_preview/simple-card"
import TestimonialAllAvatarPreview from "../../../library/components/testimonial/react/_preview/avatar"
import TestimonialAllBlogPreview from "../../../library/components/testimonial/react/_preview/blog"
import TestimonialAllRatingPreview from "../../../library/components/testimonial/react/_preview/rating"
import TestimonialAllRatingAvatarPreview from "../../../library/components/testimonial/react/_preview/rating_avatar"

//Simple
//Base sized
const TestimonialBaseSimpleBasicJsx = require("!!raw-loader!../../../library/components/testimonial/react/base/simple-basic.jsx")
const TestimonialBaseSimpleBasicHTML = require("!!raw-loader!../../../library/components/testimonial/html/base/simple-basic.html")

const TestimonialBaseSimpleBasicCenteredJsx = require("!!raw-loader!../../../library/components/testimonial/react/base/simple-basic-centered.jsx")
const TestimonialBaseSimpleBasicCenteredHTML = require("!!raw-loader!../../../library/components/testimonial/html/base/simple-basic-centered.html")

const TestimonialBaseSimpleElevatedJsx = require("!!raw-loader!../../../library/components/testimonial/react/base/simple-elevated.jsx")
const TestimonialBaseSimpleElevatedHTML = require("!!raw-loader!../../../library/components/testimonial/html/base/simple-elevated.html")

const TestimonialBaseSimpleElevatedCenteredJsx = require("!!raw-loader!../../../library/components/testimonial/react/base/simple-elevated-centered.jsx")
const TestimonialBaseSimpleElevatedCenteredHTML = require("!!raw-loader!../../../library/components/testimonial/html/base/simple-elevated-centered.html")

const TestimonialBaseSimpleElevatedPrimaryJsx = require("!!raw-loader!../../../library/components/testimonial/react/base/simple-elevated_primary.jsx")
const TestimonialBaseSimpleElevatedPrimaryHTML = require("!!raw-loader!../../../library/components/testimonial/html/base/simple-elevated_primary.html")

const TestimonialBaseSimpleElevatedPrimaryCenteredJsx = require("!!raw-loader!../../../library/components/testimonial/react/base/simple-elevated_primary-centered.jsx")
const TestimonialBaseSimpleElevatedPrimaryCenteredHTML = require("!!raw-loader!../../../library/components/testimonial/html/base/simple-elevated_primary-centered.html")

//Lg sized
const TestimonialLgSimpleBasicJsx = require("!!raw-loader!../../../library/components/testimonial/react/lg/simple-basic.jsx")
const TestimonialLgSimpleBasicHTML = require("!!raw-loader!../../../library/components/testimonial/html/lg/simple-basic.html")

const TestimonialLgSimpleBasicCenteredJsx = require("!!raw-loader!../../../library/components/testimonial/react/lg/simple-basic-centered.jsx")
const TestimonialLgSimpleBasicCenteredHTML = require("!!raw-loader!../../../library/components/testimonial/html/lg/simple-basic-centered.html")

//Avatar
//Base sized
const TestimonialBaseAvatarElevatedJsx = require("!!raw-loader!../../../library/components/testimonial/react/base/avatar-elevated.jsx")
const TestimonialBaseAvatarElevatedHTML = require("!!raw-loader!../../../library/components/testimonial/html/base/avatar-elevated.html")

const TestimonialBaseAvatarElevatedCenteredJsx = require("!!raw-loader!../../../library/components/testimonial/react/base/avatar-elevated-centered.jsx")
const TestimonialBaseAvatarElevatedCenteredHTML = require("!!raw-loader!../../../library/components/testimonial/html/base/avatar-elevated-centered.html")

const TestimonialBaseAvatarElevatedPrimaryJsx = require("!!raw-loader!../../../library/components/testimonial/react/base/avatar-elevated_primary.jsx")
const TestimonialBaseAvatarElevatedPrimaryHTML = require("!!raw-loader!../../../library/components/testimonial/html/base/avatar-elevated_primary.html")

const TestimonialBaseAvatarElevatedPrimaryCenteredJsx = require("!!raw-loader!../../../library/components/testimonial/react/base/avatar-elevated_primary-centered.jsx")
const TestimonialBaseAvatarElevatedPrimaryCenteredHTML = require("!!raw-loader!../../../library/components/testimonial/html/base/avatar-elevated_primary-centered.html")

//Blog posts
//Base sized
const TestimonialBaseBlogElevatedPrimaryJsx = require("!!raw-loader!../../../library/components/testimonial/react/base/blog-elevated_primary.jsx")
const TestimonialBaseBlogElevatedPrimaryHTML = require("!!raw-loader!../../../library/components/testimonial/html/base/blog-elevated_primary.html")

const TestimonialBaseBlogElevatedPrimaryCenteredJsx = require("!!raw-loader!../../../library/components/testimonial/react/base/blog-elevated_primary-centered.jsx")
const TestimonialBaseBlogElevatedPrimaryCenteredHTML = require("!!raw-loader!../../../library/components/testimonial/html/base/blog-elevated_primary-centered.html")

//Rating
//Base sized
const TestimonialBaseRatingElevatedJsx = require("!!raw-loader!../../../library/components/testimonial/react/base/rating-elevated.jsx")
const TestimonialBaseRatingElevatedHTML = require("!!raw-loader!../../../library/components/testimonial/html/base/rating-elevated.html")

const TestimonialBaseRatingElevatedCenteredJsx = require("!!raw-loader!../../../library/components/testimonial/react/base/rating-elevated-centered.jsx")
const TestimonialBaseRatingElevatedCenteredHTML = require("!!raw-loader!../../../library/components/testimonial/html/base/rating-elevated-centered.html")

const TestimonialBaseRatingElevatedPrimaryJsx = require("!!raw-loader!../../../library/components/testimonial/react/base/rating-elevated_primary.jsx")
const TestimonialBaseRatingElevatedPrimaryHTML = require("!!raw-loader!../../../library/components/testimonial/html/base/rating-elevated_primary.html")

const TestimonialBaseRatingElevatedPrimaryCenteredJsx = require("!!raw-loader!../../../library/components/testimonial/react/base/rating-elevated_primary-centered.jsx")
const TestimonialBaseRatingElevatedPrimaryCenteredHTML = require("!!raw-loader!../../../library/components/testimonial/html/base/rating-elevated_primary-centered.html")

//Rating Avatar
//Base sized
const TestimonialBaseRatingAvatarElevatedJsx = require("!!raw-loader!../../../library/components/testimonial/react/base/rating_avatar-elevated.jsx")
const TestimonialBaseRatingAvatarElevatedHTML = require("!!raw-loader!../../../library/components/testimonial/html/base/rating_avatar-elevated.html")

const TestimonialBaseRatingAvatarElevatedCenteredJsx = require("!!raw-loader!../../../library/components/testimonial/react/base/rating_avatar-elevated-centered.jsx")
const TestimonialBaseRatingAvatarElevatedCenteredHTML = require("!!raw-loader!../../../library/components/testimonial/html/base/rating_avatar-elevated-centered.html")

const TestimonialBaseRatingAvatarElevatedPrimaryJsx = require("!!raw-loader!../../../library/components/testimonial/react/base/rating_avatar-elevated_primary.jsx")
const TestimonialBaseRatingAvatarElevatedPrimaryHTML = require("!!raw-loader!../../../library/components/testimonial/html/base/rating_avatar-elevated_primary.html")

const TestimonialBaseRatingAvatarElevatedPrimaryCenteredJsx = require("!!raw-loader!../../../library/components/testimonial/react/base/rating_avatar-elevated_primary-centered.jsx")
const TestimonialBaseRatingAvatarElevatedPrimaryCenteredHTML = require("!!raw-loader!../../../library/components/testimonial/html/base/rating_avatar-elevated_primary-centered.html")

const TestimonialsPage = () => {
  const [activeTabs, setActiveTabs] = useState([
    {
      component_name: "TestimonialAllSimpleBasic",
      title: "Simple Basic",
      active_tab: 1,
    },
    {
      component_name: "TestimonialAllSimpleCard",
      title: "Simple Card",
      active_tab: 1,
    },
    {
      component_name: "TestimonialAllAvatar",
      title: "With avatar",
      active_tab: 1,
    },
    {
      component_name: "TestimonialAllBlog",
      title: "Blog post",
      active_tab: 1,
    },
    {
      component_name: "TestimonialAllRating",
      title: "With rating stars",
      active_tab: 1,
    },
    {
      component_name: "TestimonialAllRatingAvatar",
      title: "With avatar & rating stars",
      active_tab: 1,
    },
  ])

  const wrapperRef = useRef(null)

  const getActiveTabs = (id, tab) => {
    let updatedActiveTabs = activeTabs.map(activeTab => {
      if (activeTab.component_name === id) {
        return { ...activeTab, active_tab: tab }
      }
      return activeTab
    })
    setActiveTabs(updatedActiveTabs)
  }

  return (
    <SidebarLayout>
      <Seo
        title="Tailwind CSS Testimonials - WindUI Component Library"
        ogtitle="Tailwind CSS Testimonials - WindUI Component Library"
        description="Testimonial components are endorsements of your product or service that usually come from a customer, colleague, or peer. Built with Tailwind CSS by WindUI."
        ogdescription="Testimonial components are endorsements of your product or service that usually come from a customer, colleague, or peer. Built with Tailwind CSS by WindUI."
        url="components/testimonials/"
        ogimage={ogImage}
        author="Wind UI"
        keywords="Tailwind CSS, Tailwind CSS Testimonials, Testimonials, Testimonial Components, WindUI, windui, wind ui"
        robots="index, follow"
        revisit_after="7 days"
      />
      <div className="min-w-0 flex-1">
        {/* Hero Section */}
        <Section ariaLabelledby="intro-heading" id="intro">
          <h1 id="intro-heading">Testimonials</h1>
          <p>
            A testimonial is an honest endorsement of your product or service
            that usually comes from a customer, colleague, or peer who has
            benefited from or experienced success as a result of the work you
            did for them.
          </p>

          <section
            className="not-prose max-w-full"
            aria-multiselectable="false"
          >
            <div className="flex w-full justify-center overflow-hidden rounded-xl bg-wuiSlate-50 p-8 shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200 sm:p-16">
              <TestimonialHeroComponent />
            </div>
          </section>
        </Section>

        {/* Variations Section*/}
        <Section ariaLabelledby="variations-heading" id="variations-section">
          <h2 id="variations-heading">Variations</h2>

          {/* Simple Basic */}
          <h3 id="TestimonialAllSimpleBasic">Simple Basic</h3>

          <PreviewBlock
            id="TestimonialAllSimpleBasic"
            HtmlComponent={TestimonialBaseSimpleBasicHTML.default}
            JsxComponent={TestimonialBaseSimpleBasicJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="w-full overflow-hidden rounded-xl bg-white p-8 sm:p-16"
            >
              <TestimonialAllSimpleBasicPreview
                TestimonialBaseSimpleBasic={
                  activeTabs[0].active_tab === 1
                    ? TestimonialBaseSimpleBasicHTML.default
                    : TestimonialBaseSimpleBasicJsx.default
                }
                TestimonialBaseSimpleBasicCentered={
                  activeTabs[0].active_tab === 1
                    ? TestimonialBaseSimpleBasicCenteredHTML.default
                    : TestimonialBaseSimpleBasicCenteredJsx.default
                }
                TestimonialLgSimpleBasic={
                  activeTabs[0].active_tab === 1
                    ? TestimonialLgSimpleBasicHTML.default
                    : TestimonialLgSimpleBasicJsx.default
                }
                TestimonialLgSimpleBasicCentered={
                  activeTabs[0].active_tab === 1
                    ? TestimonialLgSimpleBasicCenteredHTML.default
                    : TestimonialLgSimpleBasicCenteredJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Simple Card */}
          <h3 id="TestimonialAllSimpleCard">Simple Card</h3>

          <PreviewBlock
            id="TestimonialAllSimpleCard"
            HtmlComponent={TestimonialBaseSimpleElevatedHTML.default}
            JsxComponent={TestimonialBaseSimpleElevatedJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="w-full overflow-hidden rounded-xl bg-wuiSlate-50 p-8 sm:p-16"
            >
              <TestimonialAllSimpleCardPreview
                TestimonialBaseSimpleElevated={
                  activeTabs[1].active_tab === 1
                    ? TestimonialBaseSimpleElevatedHTML.default
                    : TestimonialBaseSimpleElevatedJsx.default
                }
                TestimonialBaseSimpleElevatedCentered={
                  activeTabs[1].active_tab === 1
                    ? TestimonialBaseSimpleElevatedCenteredHTML.default
                    : TestimonialBaseSimpleElevatedCenteredJsx.default
                }
                TestimonialBaseSimpleElevatedPrimary={
                  activeTabs[1].active_tab === 1
                    ? TestimonialBaseSimpleElevatedPrimaryHTML.default
                    : TestimonialBaseSimpleElevatedPrimaryJsx.default
                }
                TestimonialBaseSimpleElevatedPrimaryCentered={
                  activeTabs[1].active_tab === 1
                    ? TestimonialBaseSimpleElevatedPrimaryCenteredHTML.default
                    : TestimonialBaseSimpleElevatedPrimaryCenteredJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Avatar */}
          <h3 id="TestimonialAllAvatar">With avatar</h3>

          <PreviewBlock
            id="TestimonialAllAvatar"
            HtmlComponent={TestimonialBaseAvatarElevatedHTML.default}
            JsxComponent={TestimonialBaseAvatarElevatedJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="w-full overflow-hidden rounded-xl bg-wuiSlate-50 p-8 sm:p-16"
            >
              <TestimonialAllAvatarPreview
                TestimonialBaseAvatarElevated={
                  activeTabs[2].active_tab === 1
                    ? TestimonialBaseAvatarElevatedHTML.default
                    : TestimonialBaseAvatarElevatedJsx.default
                }
                TestimonialBaseAvatarElevatedCentered={
                  activeTabs[2].active_tab === 1
                    ? TestimonialBaseAvatarElevatedCenteredHTML.default
                    : TestimonialBaseAvatarElevatedCenteredJsx.default
                }
                TestimonialBaseAvatarElevatedPrimary={
                  activeTabs[2].active_tab === 1
                    ? TestimonialBaseAvatarElevatedPrimaryHTML.default
                    : TestimonialBaseAvatarElevatedPrimaryJsx.default
                }
                TestimonialBaseAvatarElevatedPrimaryCentered={
                  activeTabs[2].active_tab === 1
                    ? TestimonialBaseAvatarElevatedPrimaryCenteredHTML.default
                    : TestimonialBaseAvatarElevatedPrimaryCenteredJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Description */}
          <h3 id="TestimonialAllBlog">Blog post</h3>

          <PreviewBlock
            id="TestimonialAllBlog"
            HtmlComponent={TestimonialBaseBlogElevatedPrimaryHTML.default}
            JsxComponent={TestimonialBaseBlogElevatedPrimaryJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="w-full overflow-hidden rounded-xl bg-wuiSlate-50 p-8 sm:p-16"
            >
              <TestimonialAllBlogPreview
                TestimonialBaseBlogElevatedPrimary={
                  activeTabs[3].active_tab === 1
                    ? TestimonialBaseBlogElevatedPrimaryHTML.default
                    : TestimonialBaseBlogElevatedPrimaryJsx.default
                }
                TestimonialBaseBlogElevatedPrimaryCentered={
                  activeTabs[3].active_tab === 1
                    ? TestimonialBaseBlogElevatedPrimaryCenteredHTML.default
                    : TestimonialBaseBlogElevatedPrimaryCenteredJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Rating */}
          <h3 id="TestimonialAllRating">With rating stars</h3>

          <PreviewBlock
            id="TestimonialAllRating"
            HtmlComponent={TestimonialBaseRatingElevatedHTML.default}
            JsxComponent={TestimonialBaseRatingElevatedJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="w-full overflow-hidden rounded-xl bg-wuiSlate-50 p-8 sm:p-16"
            >
              <TestimonialAllRatingPreview
                TestimonialBaseRatingElevated={
                  activeTabs[4].active_tab === 1
                    ? TestimonialBaseRatingElevatedHTML.default
                    : TestimonialBaseRatingElevatedJsx.default
                }
                TestimonialBaseRatingElevatedCentered={
                  activeTabs[4].active_tab === 1
                    ? TestimonialBaseRatingElevatedCenteredHTML.default
                    : TestimonialBaseRatingElevatedCenteredJsx.default
                }
                TestimonialBaseRatingElevatedPrimary={
                  activeTabs[4].active_tab === 1
                    ? TestimonialBaseRatingElevatedPrimaryHTML.default
                    : TestimonialBaseRatingElevatedPrimaryJsx.default
                }
                TestimonialBaseRatingElevatedPrimaryCentered={
                  activeTabs[4].active_tab === 1
                    ? TestimonialBaseRatingElevatedPrimaryCenteredHTML.default
                    : TestimonialBaseRatingElevatedPrimaryCenteredJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Rating and Avatar */}
          <h3 id="TestimonialAllRatingAvatar">With avatar and rating stars</h3>

          <PreviewBlock
            id="TestimonialAllRatingAvatar"
            HtmlComponent={TestimonialBaseRatingAvatarElevatedHTML.default}
            JsxComponent={TestimonialBaseRatingAvatarElevatedJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="w-full overflow-hidden rounded-xl bg-wuiSlate-50 p-8 sm:p-16"
            >
              <TestimonialAllRatingAvatarPreview
                TestimonialBaseRatingAvatarElevated={
                  activeTabs[5].active_tab === 1
                    ? TestimonialBaseRatingAvatarElevatedHTML.default
                    : TestimonialBaseRatingAvatarElevatedJsx.default
                }
                TestimonialBaseRatingAvatarElevatedCentered={
                  activeTabs[5].active_tab === 1
                    ? TestimonialBaseRatingAvatarElevatedCenteredHTML.default
                    : TestimonialBaseRatingAvatarElevatedCenteredJsx.default
                }
                TestimonialBaseRatingAvatarElevatedPrimary={
                  activeTabs[5].active_tab === 1
                    ? TestimonialBaseRatingAvatarElevatedPrimaryHTML.default
                    : TestimonialBaseRatingAvatarElevatedPrimaryJsx.default
                }
                TestimonialBaseRatingAvatarElevatedPrimaryCentered={
                  activeTabs[5].active_tab === 1
                    ? TestimonialBaseRatingAvatarElevatedPrimaryCenteredHTML.default
                    : TestimonialBaseRatingAvatarElevatedPrimaryCenteredJsx.default
                }
              />
            </div>
          </PreviewBlock>
        </Section>

        {/* Usage Section*/}
        <Section ariaLabelledby="usage-heading" id="usage-section">
          <h2 id="usage-heading">Usage</h2>
          <p>
            Use the testimonial element to let people know what other people
            think of you or your product, app or webpage.
          </p>
          <p>
            It is an essential element for content marketing as it helps to gain
            visitors’ trust quickly.
          </p>
        </Section>
        {/* Specs Section */}
        <Section ariaLabelledby="specs-heading" id="specs">
          <h2 id="specs-heading">Specs</h2>
          <p>
            To implement the user testimonial the native{" "}
            <code>&lt;blockquote&gt;</code>{" "}
            <a href="" target="_blank">
              block quotation HTML element
            </a>{" "}
            is used.
          </p>
          <p>
            A URL for the source of the quotation may be given using the cite
            attribute, while a text representation of the source can be given
            using the <code>&lt;cite&gt;</code> element.
          </p>
          <p>A testimonial can include:</p>
          <ul>
            <li>
              <strong>Testimonial wrapper : </strong> a{" "}
              <code>&lt;figure&gt;</code> HTML element.
            </li>
            <li>
              <strong>User testimonial: </strong> a paragraph with the user
              testimonial. Size can be 1.125rem (18px) or even 1.5rem(24px).
            </li>
            <li>
              <strong>User details: </strong> user name and/or profession in a{" "}
              <code>&lt;figcaption&gt;</code> HTML element.
            </li>
            <li>
              <strong>User avatar: </strong> an optional user avatar (3rem(48pr)
              in width and height).
            </li>
          </ul>
        </Section>
        {/* Accessibility Notes Section */}
        <Section
          ariaLabelledby="accessibility-notes-heading"
          id="accessibility-notes"
        >
          <h2 id="accessibility-notes-heading">Accessibility notes</h2>
          <p>
            Since the testimonial UI element is built with the native{" "}
            <code>&lt;blockquote&gt;</code> HTML element no additional
            accessibility roles are required.
          </p>
        </Section>
      </div>

      <ContentNavigation variations={activeTabs} />
    </SidebarLayout>
  )
}

export default TestimonialsPage
